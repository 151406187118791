<template>
    <div class="Items">
		<div class="ItemsListBox" :style="{'width':width}" v-for="(item,index) in rows" :key="item.pnId" @click="DetailBtn(item.pnId,item.pnType)">
			<div class="ItemsImg" :style="{'height':width}">
				<img :style="{'width':width,'height':width}" v-if="item.showType=='0'" :src="item.pnImage">
				<div :style="{'width':width,'height':width}" v-if="item.showType=='1'">
					<video
						:style="{'width':width,'height':width}"
						autoplay="" 
						controls="" 
						controlslist="nodownload" 
						loop="" 
						preload="auto" 
						:src="item.pnFile" 
					>
						您的浏览器不支持 video 标签。
					</video>
				</div>
				<div :style="{'width':width,'height':width}" v-if="item.showType=='2'">
					<audio :style="{'width':width,'height':width}" :src="item.pnFile" autoplay="" controls="">
						您的浏览器不支持 audio 标签。
					</audio>
				</div>
				<div class="play" v-if="item.type !='img'" @click.stop="playBtn(item.type,index,item.play)">
					<img style="width:32px;height:32px;" v-if="item.play == false" :src="play">
					<img style="width:32px;height:32px;" v-if="item.play == true" :src="isPlay">
				</div>
			</div>
			<div class="card-carousel--card--footer">
				<div class="name">
					{{item.pnName}}
				</div>
				<div class="Id img" style="color:rgb(112, 122, 131) !important">
					<span style="font-weight:bold;">
						<img src="../../../../assets/img/id.svg">
					</span>
					{{item.pnTokenId}}
				</div>
				<div class="Id" style="color:rgb(112, 122, 131);font-size: 14px;text-align: right;" v-if="item.ssId != 'nft_production_1'">
					非卖品
				</div>
				<div class="Id" style="color:red;font-size: 16px;text-align: right;" v-else>
					<span v-if="item.pnPrice != '0'">¥{{item.pnPrice}}</span>
				</div>
			</div>
			<div class="IconImg">
				<span>
					<font v-if="item.pnType == '2'">#{{item.pnNumb}}</font>
				</span>
				<span>
					<font @click.stop="collectBtn(item.urId,item.pnId,item.pnFavorited,index)">
						<img :src="Image" v-if="item.urId == ''">
						<img :src="loveCur" v-else>
						{{item.pnFavorited}}
					</font>
				</span>
			</div>
			<div class="IconBox">
				<span
					class="Icon" 
					v-if="item.pnMatter == '1'"
				>
					<img class="shiwu" src="../../../../assets/img/shiwu.svg">
				</span>
			</div>
		</div>
    </div>
</template>
<script>
	import Icon from "@/components/Icon"
	import Image from "../../../../assets/img/Love.png"
	import loveCur from "../../../../assets/img/loveCur.png"
	import play from "../../../../assets/img/play.png"
	import isPlay from "../../../../assets/img/isPlay.png"
	export default {
		components:{Icon},
		props:['width'],
		data() {
			return{
				rows:[],
				Image: Image,
				loveCur:loveCur,
				play: play,
				isPlay:isPlay,
				currentPage: 1,
				total: 0,
				pageSize:24,
			}
		},
		created(){
			let that = this;
			that.initData();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
		},
		methods:{
			collectBtn(urId,pnId,pnFavorited,index){
				let that = this;
				let url = "nft/pfe/save"
				let urIdStorage = window.localStorage.getItem("urId");
				if(urIdStorage == undefined || urIdStorage == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				if(urId != ""){
					url = "nft/pfe/delete"
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: url,
					data:this.$qs.stringify({
						urId: urIdStorage,
						pnId: pnId,
						urName: window.localStorage.getItem("urName"),
						urHead: window.localStorage.getItem("urHead"),
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("操作成功！");
						if(urId == ""){
							that.rows[index].urId = "12347";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) + 1;
						}else{
							that.rows[index].urId = "";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) - 1;
						}
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			playBtn(type,index,play){
				if(play== false){
					this.rows[index].play = true;
					this.rows[index].showType = "0";
					if(type == "video"){
						this.rows[index].showType = "1";
					}
					if(type == "audio"){
						this.rows[index].showType = "2";
					}
				}else{
					this.rows[index].play = false;
					this.rows[index].showType = "0";
				}
			},
			initData(){
				let that =this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'nft/pn/getListByCln',
					data:this.$qs.stringify({
						cnId: that.$route.params.cnId,
						urId: window.localStorage.getItem("urId"),
						orderType: '4',
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					that.total = res.data.totalCounts;
					that.rows = res.data.dataRoot;
					that.rows.forEach((item)=>{
						let suffix = item.pnFileType;
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
						if(suffix == ".svg" || suffix == ".png" || suffix == ".jpeg" || suffix == ".jpg" || suffix == ".gif"){
							item.type = "img";
						}
						if(suffix == ".mov" || suffix == ".mp4" || suffix == ".ogg" ||suffix == ".gltf" ||suffix == ".glb" ||suffix == ".webm" ||suffix == ".mp4" ||suffix == ".m4v" ||suffix == ".ogv"){
							item.type = "video";
						}
						if(suffix == ".mp3" || suffix == ".wav" || suffix == ".WAV" || suffix == ".OGA" || suffix == ".oga" ){
							item.type = "audio";
						}
						item.showType = "0";
						item.play = false;
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			DetailBtn(pnId,pnType){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(pnType == '0' || pnType == "1"){
					this.$router.push({
						path:'/Content/Content/'+ pnId +'/'+ pnType,
					})
				}
			}
		}
	};
</script>
<style scoped>
	video{
		object-fit: fill !important;
	}
	.ItemsListBox{
		width:282px;
		border:1px solid rgb(229, 232, 235);
		margin-left:20px;
		margin-bottom:25px;
		border-radius:10px;
		display: inline-block;
		vertical-align: top;
		position: relative;
	}
	.ItemsListBox:hover{
		box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
		transition: all 0.1s ease 0s;
		margin-top:-2px;
	}
	.ItemsImg{
		position:relative;
	}
	.ItemsImg img{
		width:282px;
		height:282px;
		object-fit:cover;
		border-top-left-radius:10px;
		border-top-right-radius:10px;
	}
	.ItemsImg .play{
		width:32px;
		height:32px;
		background-color: rgb(255, 255, 255);
		color: rgb(138, 147, 155);
		border-radius:50%;
		position: absolute;
		bottom:10px;
		right:5px;
	}
	.ItemsImg .play .el-icon{
		width:32px !important;
		height:32px !important;;
	}
	.itemsBox{
		display: flex;
		justify-content: space-between;
		padding:5px 9px;
	}
	.itemsBox .ii{
		width:48%;
		font-size:12px;
		color:rgb(112, 122, 131);
	}
	.itemsBox .left img{
		width:9px;
		height:9px;
		margin-left:5px;
	}
	.itemsBox .right{
		text-align:right;
	}
	.itemsBox .right img{
		width:14px;
		height:14px;
		margin-right:5px;
		vertical-align: bottom;
	}
	.itemsBox .fontWeight{
		font-weight:bold;
	}
	.itemsBox:last-child{
		margin-top:8px;
	}
	.itemsBox:last-child img{
		width:20px;
		height:20px;
		vertical-align: middle;
	}
	.card-carousel--card--footer{
		padding:12px !important;
	}
	.card-carousel--card--footer .name{
		font-size:16px;
		font-weight:bold;
		margin-bottom:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.card-carousel--card--footer .Id{
		height:16px;
		font-size:14px;
		margin-top:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: bold;
	}
	.shiwu{
		width:22px !important;
		height:22px !important;
		vertical-align:sub !important;
	}
	.IconImg{
	    display: flex;
	    justify-content: space-between;
	    color: rgb(112, 122, 131) !important;
	    padding: 12px;
	    background: linear-gradient(rgba(229, 232, 235, 0.392) 0%,rgb(255, 255, 255) 20%);
	    border-bottom-left-radius: 10px;
	    border-bottom-right-radius: 10px;
	}
	.IconImg span{
	    width: 50%;
	}
	.IconImg span:last-child {
	    text-align: right;
	}
	.IconImg img{
	    width: 20px !important;
	    height: 20px !important;
	    vertical-align: bottom;
	    margin-right: 5px;
	}
	.ItemsListBox .IconBox {
	    position: absolute;
	    right: 16px;
	    top: 16px;
	    z-index: 100;
	}
	.ItemsListBox .Icon {
	    width: 35px;
	    height: 35px;
	    line-height: 35px;
	    text-align: center;
	    box-shadow: 0 0 5px #ccc;
	    background: #fff;
	    border-radius: 50%;
	    padding-top: 2px;
	    box-sizing: border-box;
	    margin-left: 10px;
	    display: inline-block;
	}
</style>
