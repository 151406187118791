<template>
    <div class="Collection">
		<Header></Header>
		<div class="bannerImg">
			<img :src="obj.cnBanner">
			<div class="circle">
				<img :src="obj.cnLogo">
			</div>
			<div class="share">
				<el-button-group class="ml-4">
					<el-tooltip
						class="box-item"
						effect="dark"
						content="编辑"
						placement="top"
					>
						<el-button @click="editBtn">
							<el-icon class="el-icon--right"><Edit/></el-icon>
						</el-button>
					</el-tooltip>
					<el-tooltip
						class="box-item"
						effect="dark"
						content="删除"
						placement="top"
					>
						<el-button @click="delBtn">
							<el-icon class="el-icon--right"><Delete/></el-icon>
						</el-button>
					</el-tooltip>
					<el-tooltip
						class="box-item"
						effect="dark"
						content="打开外部链接"
						placement="top"
						v-if="obj.cnHost != ''"
					>
						<el-button @click="goToback(obj.cnHost)">
							<img style="width:25px;height:25px;" src="../../../assets/img/Web.svg">
						</el-button>
					</el-tooltip>
					<el-tooltip
						class="box-item"
						effect="dark"
						content="分享"
						placement="top"
					>
						<el-button @click="onCopy">
							<el-icon class="el-icon--right"><Share/></el-icon>
						</el-button>
					</el-tooltip>
				</el-button-group>
			</div>
		</div>
		<div class="CollectionTitle">
			<h1>
				{{obj.cnName}}
			</h1>
			<div class="text" @click="authorBtn">作者:<span style="color:#409EFF;">{{obj.urName}}</span></div>
			<div class="list">
				<div class="ii"><span>{{obj.cnItems}}</span><br>作品数量</div>
				<div class="ii"><span>{{obj.cnOwers}}</span><br>持有者</div>
				<div class="ii"><span>{{obj.cnFloor}}</span><br>底价</div>
				<div class="ii"><span>{{obj.cnTraded}}</span><br>交易额</div>
			</div>
			<div class="textCon" :class="isClass?'maxHeight':''" :style="isClass==true?maxHeight:''">
				<div ref="conHeight" v-html="obj.cnDes"></div>
			</div>
			<div :class="[isClass ? 'rotate0' : 'rotat180','default']" @click="isClass = !isClass">
				<el-icon :size="24" color="rgb(138, 147, 155)"><ArrowDownBold/></el-icon>
			</div>
		</div>
		<div class="mainTab">
			<div class="TabBox">
				<div :class="index=='1'?'cur':''" @click="tabBtn('1')"><el-icon class="el-icon--right"><Grid/></el-icon>作品</div>
				<div :class="index=='2'?'cur':''" @click="tabBtn('2')"><el-icon class="el-icon--right"><DataLine/></el-icon>热点</div>
			</div>
		</div>
		<div class="main">
			<div class="mainRight">
				<div class="mainRightBox">
					<Items ref="Items" :width="width" v-if="index == '1'"></Items>
					<Activity ref="Activity" v-if="index == '2'"></Activity>
				</div>
			</div>
		</div>
		<!-- <Footer></Footer> -->
    </div>
	<el-drawer
	    v-model="drawer"
	    :title="title"
	    size="50%"
		:destroy-on-close="true"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	>
	    <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
			<Editor ref="Editor" @onClose="onClose"></Editor>
		</div>
		<div class="drawerFooter">
			<el-button @click="drawer = false">取消</el-button>
			<el-button type="primary" @click="saveBtn" :loading="loading">保存</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import Activity from "./Component/Activity"
	import Items from "./Component/Items"
	import Editor from "@/views/UserCenter/User/Edit/Edit"
	import { Plus,Edit,Share,Search,Delete,ArrowDownBold,Histogram,Back,CloseBold,ShoppingCartFull,Grid,DataLine } from '@element-plus/icons-vue'
	export default {
		inject: ["reload"],
		components: { Header,Footer,Editor,Items,Activity,Search,Plus,Edit,Share,Delete,ArrowDownBold,Histogram,Back,CloseBold,ShoppingCartFull,Grid,DataLine },
		data() {
			return{
				isClass:false,
				showNode:true,
				drawer:false,
				loading:false,
				width:"59px",
				height:'200px',
				activeNames:'1',
				index:'1',
				obj:{},
				url:'',
				maxHeight:{
					maxHeight:""
				},
				isBreak:false
			}
		},
		created(){
			this.getObject();
			this.url = window.location.href;
		},
		watch: {
		},
		methods:{
			authorBtn(){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					this.$router.push({
						path:'/OthersMain/User/'+ this.obj.urId +'/'+ this.obj.urName,
					})
				}else{
					if(urId == this.obj.urId){
						this.$router.push({
							path:'/Main/User',
						})
					}else{
						this.$router.push({
							path:'/OthersMain/User/'+ this.obj.urId +'/'+ this.obj.urName,
						})
					}
				}
			},
			goToback(url){
				window.open(url);
			},
			onCopy(){
				this.$copyText(this.url).then(() => {
					this.$message.success("当前专辑链接已复制到您的粘贴板,快去分享给小伙伴吧！");
				})
			},
			saveBtn(){
				this.loading = true;
				this.$refs.Editor.saveBtn();
			},
			editBtn(){
				let that = this;
				that.title = "修改作品信息";
				that.drawer = true;
				that.$nextTick(()=>{
					that.$refs.Editor.getObject(that.obj.cnId);
				})
			},
			delBtn(){
				let that = this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$confirm('确定要删除吗?删除将无法恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios({
						method:'post',
						url: 'nft/cn/delete',
						headers: {
							token: token
						},
						data:this.$qs.stringify({
							cnId: that.obj.cnId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							that.$message.success("删除成功！");
							window.close();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			onClose(index){
				if(index == "0"){
					this.drawer = false;
					this.getObject();
				}
				this.loading = false;
			},
			createBtn(){
				let pnId = "123";
				this.$router.push({
					path:'/Create/'+ pnId,
				})
			},
			getObject(){
				let that =this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: token
					},
					url:'nft/cn/getView',
					data:this.$qs.stringify({
						cnId: that.$route.params.cnId
					})
				})
				.then((res)=>{
					let cnTraded = Number(res.data.data.cnTraded);
					let cnFloor = Number(res.data.data.cnFloor);
					if(cnTraded >= 1000){
						res.data.data.cnTraded = (cnTraded/1000).toFixed(2) + "K";
					}
					if(cnFloor >= 1000){
						res.data.data.cnFloor = (cnFloor/1000).toFixed(2) + "K";
					}
					that.obj = res.data.data;
					that.$nextTick(()=>{
						let conHeight = this.$refs.conHeight.offsetHeight;
						that.maxHeight.maxHeight = conHeight + 20 + "px";
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			tabBtn(num){
				this.index = num;
			},
			getScreenWidthHeight(){
				let that = this;
				let width = '';
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				let w = document.documentElement.clientWidth || document.body.clientWidth;
				this.height = h - 72 + 'px';
				if(w < 576){
					this.width = parseInt(w) - 40 + 'px';
				}else if(w > 576 && w < 768){
					width = w/2;
					this.width = parseInt(width) - 50 + "px";
				}else if(w > 768 && w < 1080){
					width = w/3;
					this.width = parseInt(width) - 50 + "px";
				}else if(w > 1080 && w < 1280){
					width = w/4;
					this.width = parseInt(width) - 50 + "px";
				}else if(w >= 1280 && w < 1900){
					width = w/5;
					this.width = parseInt(width) - 30 + "px";
				}else if(w >= 1900){
					width = w/6;
					this.width = parseInt(width) - 30 + "px";
				}
			}
		},
		beforeMount(){
			this.getScreenWidthHeight();
		},
		mounted(){
			window.addEventListener('resize', this.getScreenWidthHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.getScreenWidthHeight, false)
		}
	};
</script>
<style scoped>
	.mainTab{
		width:100%;
		height:64px;
		border-bottom:1px solid rgb(229, 232, 235);
	}
	.TabBox{
		width:360px;
		height:65px;
		margin:30px auto 0px;
		display:table;
	}
	.TabBox div{
		width:180px;
		height:65px;
		line-height:59px;
		font-size:20px;
		display:table-cell;
		text-align:center;
		vertical-align: middle;
	}
	.TabBox div .el-icon{
		margin-right:5px;
		vertical-align: text-top;
	}
	.TabBox .cur::after {
		background-color: rgb(32, 129, 226);
		content: "";
		display: block;
		height: 4px;
		width: 100%;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transition: background-color 0.4s ease 0s;
	}
	.main{
		width:100%;
		display: flex;
	}
	.mainLeft{
		height: calc(100vh - 72px);
		border-right: 1px solid rgb(229, 232, 235);
		box-sizing: border-box;
		position: sticky;
		bottom: 0px;
		top: 72px;
		flex-shrink: 0;
		background-color: rgb(255, 255, 255);
		display: inline-block;
		box-sizing: border-box;
		z-index: 10;
		align-self: flex-start;
		overflow: auto;
	}
	.header{
		height: 60px;
		padding: 16px;
		border-bottom: 1px solid rgb(229, 232, 235);
		box-sizing: border-box;
		font-size:16px;
		font-weight: bold;
		
	}
	.header:hover{
		box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
		transition: all 0.1s ease 0s;
	}
	.header .hearderL .el-icon{
		margin-right: 10px;
		transform:rotate(180deg);
		vertical-align: text-bottom;
	}
	.hearderL{
		width:50%;
		display: inline-block;
		justify-content: center;
		align-items: center;
		vertical-align: sub;
	}
	.hearderR{
		width:50%;
		text-align: right;
		display: inline-block;
		justify-content: center;
		align-items: center;
		vertical-align: sub;
		padding-right: 10px;
		box-sizing: border-box;
	}
	.mainRight{
		width:100%;
		display: inline-block;
	}
	.headerIcon{
		width:59px;
		height:60px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.headerIcon .el-icon{
		transform: rotate(180deg);
		font-size: 22px;
		font-weight:bold;
	}
	.headerIcon:hover{
		box-shadow: -5px 4px 11px -2px #6b6b6b;
	}
	.searchTitle{
		margin-bottom:15px;
		padding:20px;
		border-bottom: 1px solid rgb(229, 232, 235);
	}
	.searchTitle .box{
		padding :15px;
		border:1px solid rgb(21, 178, 229);
		background:rgba(21, 178, 229, 0.06);
		display: inline-block;
		border-radius:10px;
		margin-right:15px;
	}
	.searchTitle .box .el-icon{
		vertical-align: text-top;
	}
	.searchTitle span{
		color: rgb(32, 129, 226);
	}
	.searchBox{
		margin-bottom:20px;
	}
	.CollectionTitle .list{
		width:581px;
		height:90px;
		border:1px solid rgb(229, 232, 235);
		margin:20px auto 0px;
		border-radius: 10px;
		display: table;
	}
	.CollectionTitle .list .ii{
		width:144px;
		height:88px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		border-right:1px solid rgb(229, 232, 235);
	}
	.CollectionTitle .list .ii:last-child{
		border-right:none;
	}
	.CollectionTitle .textCon{
		max-width:800px;
		padding:20px;
		margin:0px auto;
		text-align: center;
		color: rgb(112, 122, 131);
		line-height:25px;
		font-size: 16px;
		max-height: 130px;
		box-sizing: border-box;
		word-break: break-word;
		-webkit-mask: linear-gradient(rgb(255, 255, 255) 45%, transparent);
	}
	.CollectionTitle .default{
		width:24px;
		height:24px;
		margin:0px auto;
	}
	.rotate0{
		animation: animated-rotate0 0.5s infinite;
		animation-iteration-count:1;
		animation-fill-mode:forwards;
	}
	@keyframes animated-rotate0 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(180deg);
		}
	}
	.rotate180{
		animation: animated-rotate180 0.5s infinite;
		animation-iteration-count:1;
		animation-fill-mode:forwards;
	}
	@keyframes animated-rotate180 {
		0% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
	.CollectionTitle h1{
		max-width: 600px;
		text-align: center;
		font-weight: 600;
		font-size: 40px;
		letter-spacing: 0px;
		color: rgb(4, 17, 29);
		margin:20px auto;
	}
	.CollectionTitle h1 img{
		width:30px;
		height:30px;
	}
	.CollectionTitle .text{
		width:100%;
		text-align:center;
		font-size:18px;
		color:#000;
	}
	.CollectionTitle .text font{
		color:rgb(24, 104, 183) !important;
		margin-left:10px;
	}
	.bannerImg{
		width:100%;
		height:300px;
		position: relative;
	}
	.bannerImg img{
		width:100%;
		height:220px;
		object-fit:cover;
	}
	.bannerImg .circle{
		width:130px !important;
		height:130px !important;
		border-radius:50% !important;
		margin:-70px auto 0px !important;
	}
	.bannerImg .circle img{
		width:124px !important;
		height:124px !important;;
		border-radius:50% !important;;
		border:3px solid rgb(229, 232, 235) !important;;
	}
	.share{
		position: absolute;
		bottom:0px;
		right:40px;
	}
	.share .el-button{
		height:50px;
		color:rgb(112, 122, 131);font-size: 16px;
		font-weight: 600;
	}
	.share .el-icon{
		margin-right:10px;
		font-size:20px;
	}
	.share .el-button:hover{
		background:none;
		box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
		transition: all 0.2s ease 0s;
	}
	.ml-4{
		margin-left:10px;
	}
	.el-collapse{
		border-top:none !important;
	}
	.CollectionTitle .list .ii span{
		font-size: 24px;
		font-weight:bold;
	}
	.mainRightBox{
		margin-top:25px;
	}
	.CollectionTitle .maxHeight{
		-webkit-mask:none !important;
	}
</style>
