<template>
    <div class="drawerTitle"><font color=red>*</font>专辑名称</div>
    <el-input v-model="form.cnName" placeholder="请输入专辑名称" />
    <div class="drawerTitle">外部链接</div>
    <el-input v-model="form.cnHost" placeholder="请填写外部链接地址" />
    <div class="drawerTitle"><font color=red>*</font>描述</div>
    <el-input v-model="form.cnDes" :rows="4" type="textarea" placeholder="请输入描述" />
	<div class="drawerTitle"><font color=red>*</font>专辑种类</div>
	<el-select v-model="form.cteName" placeholder="请选择" style="width:100%" @change="selectValBtn">
	    <el-option
	      v-for="item in rows"
	      :key="item.cteId"
	      :label="item.cteName"
	      :value="item"
	    />
	</el-select>
    <div class="drawerTitle"><font color=red>*</font>专辑logo 建议350 x 350</div>
    <el-upload
        class="avatar-uploader"
        :action="uploadUrl"
		:headers="headers"
        :show-file-list="false"
        :on-success="successUploadLogo"
        :before-upload="beforeAvatarUploadLogo"
    >
        <img v-if="form.cnLogo != ''" :src="form.cnLogo" class="avatar" />
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
    <div class="drawerTitle"><font color=red>*</font>专辑封面 建议600 x 400</div>
    <el-upload
        class="avatar-uploader"
        :action="uploadUrl"
        :show-file-list="false"
        :on-success="successUploadCover"
        :before-upload="beforeAvatarUploadCover"
		:headers="headers"
      >
        <img v-if="form.cnFeatured != ''" :src="form.cnFeatured" class="avatar" />
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
    <div class="drawerTitle"><font color=red>*</font>专辑横幅 建议1400 x 400</div>
    <el-upload
        class="avatar-uploader"
        :action="uploadUrl"
        :show-file-list="false"
        :on-success="successUploadBanner"
        :before-upload="beforeAvatarUploadBanner"
		:headers="headers"
      >
        <img v-if="form.cnBanner != ''" :src="form.cnBanner" class="avatar" />
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
</template>
<script>
	import { Plus } from '@element-plus/icons-vue'
	import { ElLoading } from 'element-plus'
	export default {
		components:{ Plus },
		data() {
			return{
				uploadUrl:this.$uploadUrl +'common/oss/uploadTemp',
				headers:{
					token: window.localStorage.getItem("token")
				},
				loading:null,
				rows:[],
				form:{
					cnId:"",
					cnName:'',
					cnLogo:'',
					cnFeatured:'',
					cnBanner:'',
					cnHost:'',
					cnDes:'',
					cteId:'',
					cteName:'',
					urId:window.localStorage.getItem("urId"),
					urName:window.localStorage.getItem("urName")
				}
			}
		},
		created(){
			this.getList();
		},
		methods:{
			saveBtn(){
				let that = this;
				let cnName = this.form.cnName;
				let cnHost = this.form.cnHost;
				let cnDes = this.form.cnDes;
				let cteId = this.form.cteId;
				let cnLogo = this.form.cnLogo;
				let cnFeatured = this.form.cnFeatured;
				let cnBanner = this.form.cnBanner;
				if(cnName == ''){
					that.$message.error("请填写专辑名称!");
					return false;
				}
				if(cnDes == ''){
					that.$message.error("请填写描述!");
					return false;
				}
				if(cteId == ''){
					that.$message.error("请选择专辑种类!");
					return false;
				}
				if(cnLogo == ''){
					that.$message.error("请上传专辑logo!");
					return false;
				}
				if(cnFeatured == ''){
					that.$message.error("请上传专辑封面!");
					return false;
				}
				if(cnBanner == ''){
					that.$message.error("请上传专辑横幅!");
					return false;
				}
				let url = "nft/cn/save";
				if(that.form.cnId != ""){
					url = "nft/cn/update"
				}
				this.$axios({
					method:'post',
					url:url,
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify(that.form)
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("保存成功!");
						that.$emit("onClose","0",that.form);
					}else{
						that.$message.error(msg);
						that.$emit("onClose","1");
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			selectValBtn(val){
				this.form.cteName = val.cteName;
				this.form.cteId = val.cteId;
			},
			getObject(cnId){
				let that =this;
				this.$axios({
					method:'post',
					headers: {
						token:window.localStorage.getItem("token")
					},
					url:'nft/cn/getObject',
					data:this.$qs.stringify({
						cnId: cnId
					})
				})
				.then((res)=>{
					that.form = res.data.data;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			beforeAvatarUploadLogo(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
					this.loading.close();
					return false;
				}
				if (!isLt5M) {
				  this.$message.error('上传头像图片大小不能超过 5MB!');
					this.loading.close();
					return false;
				}
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				return isJPG && isLt5M;
			},
			successUploadLogo(res,file){
				this.form.cnLogo = res.path;
				this.loading.close();
			},
			beforeAvatarUploadCover(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
					this.loading.close();
					return false;
				}
				if (!isLt5M) {
				  this.$message.error('上传头像图片大小不能超过 5MB!');
					this.loading.close();
					return false;
				}
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				return isJPG && isLt5M;
			},
			successUploadCover(res,file){
				this.form.cnFeatured = res.path;
				this.loading.close();
			},
			beforeAvatarUploadBanner(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
					this.loading.close();
					return false;
				}
				if (!isLt5M) {
				  this.$message.error('上传头像图片大小不能超过 5MB!');
					this.loading.close();
					return false;
				}
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				return isJPG && isLt5M;
			},
			successUploadBanner(res,file){
				this.form.cnBanner = res.path;
				this.loading.close();
			},
			getList(){
				this.$axios({
					method:'post',
					headers: {
						token:window.localStorage.getItem("token")
					},
					url:'nft/cte/getAll',
					data:this.$qs.stringify({})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					this.rows = rows;
				})
				.catch((err)=>console.log('请求失败',err))
			}
		}
	};
</script>
<style scoped>
	.drawerTitle{
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.el-upload img{
		object-fit: scale-down;
	}
</style>
